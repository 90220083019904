import { Text } from "@clipboard-health/ui-react";
import { isPlatform } from "@ionic/core";
import { IonSpinner } from "@ionic/react";
import { Button, Stack } from "@mui/material";
import { NfcReaderError, NfcReaderErrorType } from "@src/appV2/Nfc/utils/nfcReaderError";
import { useQueryClient } from "@tanstack/react-query";

import { type NfcTagRequest } from "../../../Shift/types";
import { type NfcHashValidationAction } from "../../api/types";
import { NfcHashValidationResult, useNfcHashValidation } from "../../api/useNfcHashValidation";
import { ScanInstructionDetails } from "../ScanInstructionDetails";
import { ReadingFailureType } from "../types";
import { useNfcHashReader } from "../useNfcHashReader";
import { clockActionToReadableString } from "../utils";

interface ScanPendingStageProps {
  shiftId: string;
  clockAction: NfcHashValidationAction;
  facilityName: string;
  nfcTagRequests: NfcTagRequest[];

  onFail: (failureType: ReadingFailureType, errorCode?: string) => void;
  onSuccess: () => void;
  onCancel: () => void;
}

export function ScanPendingStage({
  shiftId,
  clockAction,
  facilityName,
  nfcTagRequests,
  onFail,
  onSuccess,
  onCancel,
}: ScanPendingStageProps) {
  const queryClient = useQueryClient();
  const { mutate: validateNfcHash, isLoading: isValidationStarted } = useNfcHashValidation();

  const handleNfcReadError = (error: unknown) => {
    if (!(error instanceof NfcReaderError)) {
      onFail(ReadingFailureType.OTHER, "UNKNOWN");
      return;
    }

    switch (error.type) {
      case NfcReaderErrorType.SCAN_CANCEL: {
        onCancel();
        return;
      }

      case NfcReaderErrorType.SCAN_TIMEOUT: {
        onFail(ReadingFailureType.SCAN_TIMEOUT, error.type);
        return;
      }

      case NfcReaderErrorType.DISABLED: {
        onFail(ReadingFailureType.NFC_DISABLED, error.type);
        return;
      }

      case NfcReaderErrorType.SCAN_FAILED:
      case NfcReaderErrorType.SCAN_PARSING_FAILED:
      case NfcReaderErrorType.SCAN_ALREADY_IN_PROGRESS:
      case NfcReaderErrorType.UNSUPPORTED: {
        onFail(ReadingFailureType.OTHER, error.type);
        return;
      }

      default: {
        error.type satisfies never;
      }
    }
  };

  const handleNfcValidationResult = (validationResult: NfcHashValidationResult) => {
    switch (validationResult) {
      case NfcHashValidationResult.SUCCESS: {
        onSuccess();
        return;
      }

      case NfcHashValidationResult.INVALID_HASH:
      case NfcHashValidationResult.INACTIVE_HASH:
      case NfcHashValidationResult.INCORRECT_HASH: {
        onFail(ReadingFailureType.INVALID_HASH, validationResult);
        return;
      }

      case NfcHashValidationResult.OTHER_FAILURE:
      case NfcHashValidationResult.NETWORK_ERROR: {
        onFail(ReadingFailureType.OTHER, validationResult);
        return;
      }

      default: {
        validationResult satisfies never;
      }
    }
  };

  const stopScanner = async () => {
    await queryClient.cancelQueries({ queryKey: ["nfc-hash-scan"] });
    onCancel();
  };

  useNfcHashReader({
    onError: handleNfcReadError,
    onSuccess: async (nfcHash: string) => {
      validateNfcHash(
        { shiftId, hashString: nfcHash, action: clockAction },
        { onSuccess: handleNfcValidationResult }
      );
    },
  });

  return (
    <Stack minHeight="100%">
      <Stack flexGrow="1" spacing={3}>
        <Text bold variant="h2">
          Use a Clipboard Health NFC Time Clock to {clockActionToReadableString(clockAction)}
        </Text>
        <ScanInstructionDetails facilityName={facilityName} nfcTagRequests={nfcTagRequests} />
      </Stack>

      <Stack spacing={2} mt={2}>
        <Button disabled variant="contained">
          {isPlatform("android") && <IonSpinner class="ion-margin-end" />}

          {isValidationStarted ? "Please wait, validating..." : "Scan the NFC poster..."}
        </Button>
        <Button variant="outlined" onClick={stopScanner}>
          Go Back
        </Button>
      </Stack>
    </Stack>
  );
}
